.main {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 32px 130px;
  margin: 0 auto;
}

.main__title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #acacac;
  margin: 0;
}

.main__product {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  margin: 0;
  padding-top: 16px;
}

.main__producer {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin: 0;
  padding-top: 10px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.main__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.main__form-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 360px 202px 1fr;
  gap: 35px;
}

.main__category-block {
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 20px;
}

.main__select-box {
  width: 100%;
  display: grid;
  gap: 8px;
}

.main__category-button {
  width: fit-content;
  display: flex;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.main__category-button:hover {
  opacity: 0.7;
}

.main__description-block {
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 20px;
}

.main__photo-box {
  width: 100%;
  display: grid;
  gap: 8px;
}

.main__photo-grid-box {
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg,
      rgba(72, 97, 123, 0.07),
      rgba(72, 97, 123, 0.07)),
    #ffffff;
  border-radius: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 16px;
  transition: border-color 0.3s ease, opacity 0.3s ease;
}

.main__photo-grid-box_error {
  border-color: #ba1b1b;
}

.main__photo-grid-box_valid {
  border-color: #00d56e;
}

.main__photo-grid-box_disabled {
  opacity: 0.5;
  border-color: transparent;
  pointer-events: none;
}

.main__photo-grid-box_type_reviewer {
  border-color: transparent;
  pointer-events: none;
}

.main__photo-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 50px);
  grid-auto-rows: 50px;
  justify-content: space-between;
  gap: 18px;
}

.main__photo-item {
  position: relative;
}

.main__photo {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  object-fit: cover;
  object-position: center;
}

.main__remove-photo-button {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -7px;
  top: -7px;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.remove-button-icon {
  width: 100%;
  height: 100%;
}

.remove-button-icon-fill {
  transition: fill 0.3s ease-in;
}

.main__remove-photo-button:hover .remove-button-icon-fill {
  fill: #9d1414;
}

.main__input-image-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 12px;
}

.main__input-image-label {
  display: flex;
  cursor: pointer;
}

.main__input-image {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  z-index: 10;
}

.add-item-icon-circle {
  transition: fill 0.3s ease-in;
}

.main__input-image-label:hover .add-item-icon-circle {
  fill: #00a297;
}

.main__photo-container {
  background: #ffffff;
  border-radius: 12px;
}

.main__photo-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
  padding-top: 20px;
}

.main__no-item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin: 0;
  justify-self: flex-end;
}

.main__input-box {
  width: 100%;
  height: fit-content;
  display: grid;
  justify-items: end;
  gap: 8px;
}

.main__label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin: 0;
  justify-self: start;
  pointer-events: none;
}

.main__label_type_writer::after {
  content: "*";
  color: #ff0000;
}

.main__input {
  width: 100%;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background: linear-gradient(0deg,
      rgba(72, 97, 123, 0.07),
      rgba(72, 97, 123, 0.07)),
    #ffffff;
  border-radius: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 18px 16px;
  transition: border-color 0.3s ease, opacity 0.3s ease;
}

.main__input::placeholder {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #666666;
}

.main__input:hover {
  border-color: #48617b;
}

.main__input:focus {
  border-color: #00504a;
  outline: transparent;
}

.main__input_type_textarea {
  height: 98px;
  resize: none;
}

.main__input_error {
  border-color: #ba1b1b;
}

.main__input_valid {
  border-color: #00d56e;
}

.main__input_disabled {
  opacity: 0.5;
  border-color: transparent;
  pointer-events: none;
}

.main__input_type_reviewer {
  border-color: transparent;
  pointer-events: none;
}

.main__input_type_reviewer:hover {
  border-color: transparent;
}

.main__input_type_reviewer-source {
  color: #ffffff;
  background: #05cabe;
}

.main__error-message {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 10px;
  color: #ba1b1b;
  justify-self: flex-end;
}

.main__submit-error {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 16px;
  color: #ba1b1b;
}

.main__source-block {
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 20px;
}

.main__source-input-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: center;
  gap: 10px;
}

.main__source-input-box_type_performer {
  grid-template-columns: 1fr;
}

.main__copy-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.main__copy-button_hidden {
  display: none;
}

.main__copy-button:hover {
  opacity: 0.7;
}

.main__characteristic-block {
  width: 100%;
  max-width: 302px;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.main__charasteristic-list {
  width: 100%;
  display: grid;
  gap: 20px;
  margin-top: 8px;
}

.main__characteristic-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 190px;
}

.main__characteristic-label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #191c1c;
  pointer-events: none;
}

.main__extra-block {
  width: 100%;
  display: grid;
  gap: 22px;
}

.main__extra-list {
  width: 100%;
  display: grid;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main__extra-item {
  width: 100%;
  display: grid;
  grid-template-columns: 20px repeat(2, 1fr);
  align-items: center;
  gap: 12px;
}

.main__remove-extra-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.main__remove-extra-button:hover .remove-button-icon-fill {
  fill: #9d1414;
}

.main__add-extra-button {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f4f6;
  border-radius: 60px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}

.main__add-extra-button_disabled {
  opacity: 0.3;
  pointer-events: none;
}

.main__add-extra-text {
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  padding-right: 8px;
  transition: color 0.3s ease-in;
}

.main__add-extra-button:hover .main__add-extra-text {
  color: #1b3532;
}

.main__add-extra-button .add-item-icon {
  width: 24px;
  height: 24px;
}

.main__add-extra-button:hover .add-item-icon-circle {
  fill: #00a297;
}

.main__extra-checkbox {
  width: 100%;
  display: grid;
  gap: 8px;
}

.main__buttons-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 115px;
}

.main__button {
  width: 240px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 60px;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.main__button:hover {
  opacity: 0.7;
}

.main__button_type_report {
  background: rgba(255, 0, 0, 0.12);
  color: #ff0000;
}

.main__button_type_skip {
  color: #000000;
  background: transparent;
}

.main__button_type_submit {
  color: #ffffff;
  background: #05cabe;
}

.main__button_type_submit_disabled {
  background: #c5c7c6;
  pointer-events: none;
}

.main__btn {
  background: linear-gradient(0deg, rgba(72, 97, 123, 0.07), rgba(72, 97, 123, 0.07)), #FFFFFF;
  border: none;
  width: fit-content;
  padding: 0;
  padding: 12px 24px;
  border-radius: 12px;
  margin-top: 8px;
  align-self: flex-end;
  transition: background-color 0.3s ease-in-out;
}

.main__btn_error {
  background-color: rgba(255, 0, 0, 0.12);
  ;
}

.main__paste-error {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 14px;
  color: #ba1b1b;
  align-self: flex-end;
  text-align: right;
}

.main__paste-text {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 14px;
  color: #191c1c;
  align-self: flex-end;
  text-align: right;
  white-space: pre-line;
}

.main__product-search-link {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #00BFB4;
  margin: 6px 0 0;
  text-decoration: none;
  border-bottom:  1px solid #00BFB4;
  width: fit-content;
}

.main__product-search-link:last-of-type {
  margin-bottom: 24px;
}

.main__input_type_textarea-paste{
  margin: 12px 0 0;
}

@media (max-width: 1280px) {
  .main__form {
    margin-top: 50px;
  }

  .main__form-box {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 70px;
  }

  .main__characteristic-block {
    max-width: unset;
  }

  .main__characteristic-box {
    grid-template-columns: 110px 1fr;
    gap: 12px;
  }
}

@media (max-width: 900px) {
  .main {
    padding: 44px 12px 80px;
  }
}

@media (max-width: 770px) {
  .main__buttons-box_type_reviewer {
    max-width: 400px;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 680px) {
  .main__title {
    font-size: 16px;
  }

  .main__product {
    font-size: 24px;
  }

  .main__producer {
    font-size: 16px;
  }

  .main__form-box {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  .main__characteristic-box {
    grid-template-columns: 90px 1fr;
  }
}
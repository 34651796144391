.select {
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 8px;
  position: relative;
}

.select__label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  pointer-events: none;
}

.select__label_type_writer::after {
  content: "*";
  color: #ff0000;
}

.select__input-box {
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer !important;
}

.select__input {
  width: 100%;
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background: linear-gradient(
      0deg,
      rgba(72, 97, 123, 0.07),
      rgba(72, 97, 123, 0.07)
    ),
    #ffffff;
  border-radius: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 18px 44px 18px 16px;
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.select__input::placeholder {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #666666;
}

.select__input:hover {
  border-color: #48617b;
}

.select__input:focus {
  border-color: #00504a;
  outline: transparent;
}

.select__input_error {
  border-color: #ba1b1b;
}

.select__input_valid {
  border-color: #00d56e;
}

.select__input_type_reviewer {
  border-color: transparent;
  pointer-events: none;
}

.select__input_type_reviewer:hover {
  border-color: transparent;
}

.select__error-message {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 10px;
  color: #ba1b1b;
  justify-self: flex-end;
}

.select__arrow-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  bottom: 18px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in;
}

.select__arrow-icon_reverse {
  transform: rotate(180deg);
}

.select__popup {
  width: 100%;
  display: grid;
  gap: 4px;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 108px;
  z-index: 100;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.select__popup_type_reason {
  top: 68px;
}

.select__popup_opened {
  visibility: visible;
  opacity: 1;
}

.select__overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.select__popup_opened + .select__overlay {
  width: 100vw;
  opacity: 0;
}

.select__items-list {
  width: 100%;
  display: grid;
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.select__item {
  background: #c3ece9;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 4px 6px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.select__item_selected {
  background: #05cabe;
}

.select__item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  margin: 0;
}

.header {
  width: 100%;
  background: #05cabe;
}

.header__content-box {
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px 32px;
  margin: 0 auto;
}

.header__logo {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  cursor: pointer;
}

.header__icons-box {
  display: none;
}

.header__info-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  align-items: center;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header__item {
  display: flex;
  align-items: center;
  background: #00a297;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px;
}

.header__info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  margin: 0;
}

.header__user-icon-box {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
}

.header__open-button {
  display: none;
}

@media (max-width: 900px) {
  .header__content-box {
    padding: 24px 12px;
  }

  .header__logo {
    font-size: 32px;
  }

  .header__info-text {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .header__nav-wrapper {
    max-width: 400px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -400px;
    background: #05cabe;
    opacity: 1;
    z-index: 10;
    box-sizing: border-box;
    padding: 24px 20px;
    transition: right 0.5s ease;
  }

  .header__nav-box_menu-open .header__nav-wrapper {
    right: 0;
  }

  .header__nav-box_menu-open .header__background {
    width: 100vh;
    opacity: 0.4;
    transition: width 0s ease 0s, opacity 0.5s ease;
  }

  .header__background {
    width: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: #000;
    opacity: 0;
    z-index: 9;
    transition: width 0s ease 0.5s, opacity 0.5s ease;
  }

  .header__nav-wrapper-box {
    display: flex;
    flex-direction: column;
  }

  .header__icons-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__close-button {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .header__info-list {
    grid-template-columns: 1fr;
    gap: 28px;
    padding-top: 42px;
  }

  .header__open-button {
    width: 52px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
}

.checkbox {
  display: grid;
  grid-template-columns: 1fr 18px;
  align-items: center;
  gap: 8px;
  justify-self: flex-end;
}

.checkbox__label-text {
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 150%;
}

.checkbox__label-text_type_characteristic {
  font-size: 10px;
}

.checkbox__invisible-input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__pseudo-item {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(72, 97, 123, 0.07),
      rgba(72, 97, 123, 0.07)
    ),
    #ffffff;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease, opacity 0.3s ease;
}

.checkbox__pseudo-item:hover {
  border-color: #05cabe;
}

.checked-checkbox-icon {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.checkbox__pseudo-item_checked .checked-checkbox-icon {
  opacity: 1;
}

.checkbox__pseudo-item_disabled {
  opacity: 0.5;
  pointer-events: none;
}

.checkbox__pseudo-item_type_wrong:hover {
  border-color: #ba1b1b;
}

.checkbox__pseudo-item_type_wrong .checked-checkbox-icon-fill {
  fill: #ba1b1b;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  padding: 12px;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup__container {
  width: 100%;
  max-width: 408px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #ffffff;
  border-radius: 36px;
  box-sizing: border-box;
  padding: 24px;
  z-index: 10;
}

.popup__close-button {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0 0 0 auto;
  cursor: pointer;
}

.popup__container .close-button-icon-stroke {
  stroke: #000000;
}

.popup__title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  margin: 0;
  padding-top: 36px;
}

.popup__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  margin: 0;
  padding-top: 8px;
}

.popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.popup__form-container {
  width: 100%;
  display: grid;
  gap: 24px;
}

.popup__buttons-box {
  width: 100%;
  display: grid;
  gap: 32px;
  justify-items: center;
  margin-top: 32px;
}


.popup__item-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  margin: 0;
  padding-top: 8px;
}
.auth {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 12px;
}

.auth__form-box {
  width: 100%;
  max-width: 408px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 36px;
  box-sizing: border-box;
  padding: 24px;
  margin: 44px 0 68px;
}

.auth__title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  margin: 0;
}

.auth__form {
  width: 100%;
  display: grid;
  gap: 24px;
  margin-top: 48px;
}

.auth__input-box {
  display: grid;
  gap: 8px;
  position: relative;
}

.auth__label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

.auth__input {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  background: linear-gradient(
      0deg,
      rgba(72, 97, 123, 0.07),
      rgba(72, 97, 123, 0.07)
    ),
    #ffffff;
  border-radius: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 16px;
  transition: border-color 0.3s ease;
}

.auth__input::placeholder {
  font-family: "Jost";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #666666;
}

.auth__input:hover {
  border-color: #48617b;
}

.auth__input:focus {
  border-color: #00504a;
  outline: transparent;
}

.auth__input_error {
  border-color: #ba1b1b;
}

.auth__input_valid {
  border-color: #00d56e;
}

.auth__error-message {
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 12px;
  color: #ba1b1b;
}

.auth__submit-button {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  font-family: "Jost";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  background: #05cabe;
  border-radius: 60px;
  border: none;
  box-sizing: border-box;
  padding: 12px 90px;
  margin-top: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
}

.auth__submit-button:hover {
  background: #00a297;
}

.auth__submit-button_disabled {
  background: #c5c7c6;
  pointer-events: none;
}

@media (max-width: 550px) {
  .auth__title {
    font-size: 28px;
  }

  .auth__input:hover {
    border-color: transparent;
  }

  .auth__submit-button:hover {
    background: #05cabe;
  }
}
